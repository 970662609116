import * as z from 'zod'
import { useState } from 'react'
import {
  VStack,
  Text,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem
} from '@chakra-ui/react'
import { Input } from '@/component/Form/Input'
import { Mail, Lock } from '@/component/Icon'
import NextLink from 'next/link'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { Toast } from '@/utils/toast'
import { getSubdomain } from '@/services/subdomain'
import { GetServerSideProps } from 'next'
import { getServerSession } from 'next-auth'
import { authOptions } from './api/auth/[...nextauth]'
import { fetchRandomImage } from '@/services/unsplash'
import Image from 'next/image'
import { api } from '@/services/api'
import { TenantsProps, Tenants } from '@/interfaces/Tenants'

const DEFAULT_TENANT = {
  name: 'gptmax',
  logo: '/logo-gptmax.svg',
  logo_extended: null
}

const FormSchema = z.object({
  email: z
    .string()
    .nonempty('Campo obrigatório')
    .email('E-mail inválido')
    .max(100, 'Máximo de 100 caracteres'),
  password: z
    .string()
    .nonempty('Campo obrigatório')
    .min(8, 'A senha deve conter pelo menos 8 caracteres')
    .max(100, 'Máximo de 100 caracteres')
})

type FormDataProps = z.infer<typeof FormSchema>

interface Props {
  tenant: Tenants
}

export default function Login({ tenant }: Props) {
  const router = useRouter()
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormDataProps>({
    resolver: zodResolver(FormSchema)
  })

  const handleLogin = async (values: FormDataProps) => {
    try {
      setIsLoadingAccount(true)
      let res = await signIn('credentials', {
        email: values.email,
        password: values.password,
        callbackUrl: (router.query?.callbackUrl as string) ?? `/dashboard`,
        redirect: false
      })

      if (res?.ok) {
        reset()
        Toast({
          title: 'Bem vindo(a)!',
          status: 'success'
        })
        if (tenant.url !== 'gptmax') {
          await router.push(`/chat`)
        } else {
          await router.push(res.url || '/dashboard')
        }
      } else {
        reset({ password: '' })
        Toast({
          title: 'Acesso a conta',
          description:
            'Não foi possível acessar a sua conta, verifique os dados e acesso e tente novamente.',
          status: 'warning'
        })
      }
    } catch (e) {
      Toast({
        title: 'Acesso a conta',
        description:
          'Parece que houve uma falha ao tentar acessar sua conta, tente novamente em alguns minutos.',
        status: 'error'
      })
    } finally {
      setIsLoadingAccount(false)
    }
  }

  return (
    <Grid
      h="100vh"
      w="100%"
      bgImage={tenant.image_background}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
      p={8}
      position={'relative'}
    >
      <GridItem>
        <Flex w={'full'} h={'full'} justify={'center'} alignItems={'center'}>
          <VStack
            spacing={8}
            w="full"
            maxW="430px"
            bg={'rgba(255, 255, 255, 0.7)'}
            backdropFilter={'blur(20px)'}
            borderRadius="xl"
            p={[4, 8]}
            boxShadow="4px 4px 4px 0px #00000040"
          >
            <Image
              src={tenant.logo as string}
              width={256}
              height={32}
              alt="Company Logo"
            />

            <Text fontWeight="bold" fontSize="2xl" color={'#385CA9'}>
              Acesse sua conta
            </Text>
            <VStack
              as="form"
              onSubmit={handleSubmit(handleLogin)}
              spacing={6}
              w="full"
            >
              <Input
                {...register('email')}
                type="email"
                leftElement={<Mail w="22px" h="22px" stroke={'#385CA9'} />}
                placeholder="E-mail"
                error={errors.email}
                bgColor={'#F8F6FD'}
                borderColor={'#D9E2FF'}
                color={'grey.300'}
                _focus={{
                  borderColor: '#385ca9',
                  bgColor: '#F8F6FD'
                }}
                _hover={{
                  borderColor: '#385ca9'
                }}
              />
              <Input
                {...register('password')}
                type="password"
                securityEntry
                placeholder="Senha"
                leftElement={<Lock w="22px" h="22px" stroke={'#385CA9'} />}
                error={errors.password}
                bgColor={'#F8F6FD'}
                borderColor={'#D9E2FF'}
                color={'grey.600'}
                _focus={{
                  borderColor: '#385ca9',
                  bgColor: '#F8F6FD'
                }}
                _hover={{
                  borderColor: '#385ca9'
                }}
              />
              <Button
                size="md"
                color="#fff"
                bgColor={'#385ca9'}
                variant="solid"
                type="submit"
                isLoading={isSubmitting || isLoadingAccount}
                loadingText="Aguarde"
                w="full"
                _focus={{
                  bgColor: 'rgba(56, 92, 169, 0.8)'
                }}
                _hover={{
                  bgColor: 'rgba(56, 92, 169, 0.8)'
                }}
              >
                Entrar
              </Button>
            </VStack>

            <Flex w="full" align="center" gap="11px">
              <Divider borderColor="#385CA9" />
              <Text color="#385CA9">ou</Text>
              <Divider borderColor="#385CA9" />
            </Flex>

            <Flex fontSize="sm" justify="center" align="center">
              <NextLink href="/recovery-password" passHref>
                <Text color={'#385CA9'} fontWeight="bold">
                  Esqueci a senha
                </Text>
              </NextLink>
            </Flex>

            <Flex
              w="full"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              gap={1}
            >
              <Text color={'#262626'} fontWeight={'400'} fontSize={'10px'}>
                Powered by
              </Text>
              <Image
                width={164}
                height={12}
                src="/logo-abstrato.svg"
                alt="Abstrato Logo"
              />
            </Flex>
          </VStack>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions)
  if (session) {
    return {
      props: {},
      redirect: {
        destination: '/dashboard'
      }
    }
  }

  try {
    const jwt = process.env.API_TOKEN

    const host = context.req.headers.host
    const subdomain = getSubdomain(host as string)

    if (subdomain === 'gptmax' || subdomain === 'development' || !subdomain) {
      const backgroundImageSrc = await fetchRandomImage()
      return {
        props: {
          tenant: {
            name: 'gptmax',
            image_background: backgroundImageSrc,
            logo: '/logo-gptmax.svg',
            logo_extended: null
          }
        }
      }
    } else {
      const { data } = await api.get<TenantsProps>(`/api/tenants/`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })

      const tenant = data.items.find((item) => item.url === subdomain)

      return {
        props: {
          tenant: tenant ?? DEFAULT_TENANT
        }
      }
    }
  } catch {
    return {
      props: {
        tenant: DEFAULT_TENANT
      }
    }
  }
}
